import React from "react";
import moment from 'moment';
import * as FaIcons from "react-icons/fa";
import i18n from "i18n-react";
import DefaultModal from '../../components/DefaultModal';
import DefaultSpinner from "../../components/DefaultSpinner";
import { DefaultAjax } from "../../components/DefaultAjax";
import {  Row, Col, Table } from 'reactstrap';
import SelectGIE from '../../components/SelectGIE';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DefaultPagination from '../../components/DefaultPagination';
import { renderElectronicDocumentRow, getDataForSearchOrShowAll } from "./InvoiceFunctions";
import { dateRangeForSearh, handleDateRangeCallback, catchErrorAjax, toastFunction, calculateScroll, 
    handleIndexDefaultSearchButton } from "../../components/mainFunctions"; 
import PropTypes from 'prop-types';
import InvoiceShow from "./InvoiceShow";
import DefaultModalError from "../../components/DefaultModalError";

export const url = process.env.REACT_APP_API_URL;

class ElectronicDocument extends React.Component {
    constructor(props){
        super(props);
        this.state={
            modalContent:"",
            modalContent2:"",
            instanceTypeDescription:"",
            castMzate:"",
            spinner:"",
            token: props.token, 
            startDate: moment().add(),
            endDate: moment().add(),
            selectOrgType:"",
            dateRange: [],
            arrayElectronicDocument: [],
            offset: 0,
            perPage: 24,
            currentPage: 1,
            paginationHtml:"",
            contentPagination: "",
            currentEDocument: "",
            eDocumentInnominated: false,
            eDocumentForEdit:"",
            numberOfPendingDocuments:0,
            lastExecution:"",
            nextExecution:"",
            eDocumentStatus:0,
            canBeForwarded: false,
            language: props.language
        } 
        this.state.dateRange.push(dateRangeForSearh(new Date(moment())));
        this.state.dateRange.push(dateRangeForSearh(new Date(moment())));
    } 

    componentDidMount(){
        this.setState({
            selectOrgType: <SelectGIE classSelect={"col-sm-12 col-md-2 col-lg-2 col-xl-2 selectGIE"} classSelectType={"col-sm-12 col-md-2 col-lg-2 col-xl-2 displayNone"}/>
        })
        calculateScroll(80);
        this.initElectronicDocumentIndex();
        setTimeout(() => {
            this.keyDownHandler = (e) => handleIndexDefaultSearchButton(e, () => this.getElectronicDocument(true));
            document.addEventListener('keydown', this.keyDownHandler);
        }, 10);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keyDownHandler);
    }

    initElectronicDocumentIndex = () => {
        this.setState({modalContent:""});
        this.getElectronicDocument();
        this.getNumberOfPendingDocuments();
        this.sendDocumentsInfo();
    } 

    sendDocumentsInfo = () => {
        DefaultAjax('').get('/fePyElectronicDocument/sendDocumentsInfo')
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                this.setState({
                    lastExecution:result.lastExecution,
                    nextExecution:result.nextExecution,
                    eDocumentStatus:parseInt(result.status)
                })
            }
        }).catch((error) =>{
            catchErrorAjax(error, this.setState.bind(this));
        });
    } 

    getNumberOfPendingDocuments = () => {
        DefaultAjax('').get('/fePyElectronicDocument/numberOfPendingDocuments')
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                this.setState({numberOfPendingDocuments:result})
            }
        }).catch((error) =>{
            catchErrorAjax(error, this.setState.bind(this));
        });
    } 

    getElectronicDocument = (isSearch,isShowAll) => {
        this.setState((prevState) => ({
            contentPagination: "",
            modalContent: "",
            spinner: <DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").value}/>
        }))
        setTimeout(() => {
            const params = getDataForSearchOrShowAll(isSearch,isShowAll,this.state.dateRange[0],this.state.dateRange[1],this.state.perPage,this.state.currentPage,this.setState.bind(this));
            
            DefaultAjax('').get(`/fePyElectronicDocument/index?${params.toString()}`)
            .then((res) => {
                if(res){
                    let result = JSON.parse(res.data);
                    this.setState({
                        spinner:"",
                        arrayElectronicDocument: result.edList
                    })

                    const contentPagination = result.edList.map(billing =>
                        renderElectronicDocumentRow(billing, this.showElectronicDocument)
                    );

                    this.setState({
                        pageCount: Math.ceil(result.totalCount / this.state.perPage),
                        
                        contentPagination
                    })
                    setTimeout(()=> {
                        this.setState({
                            paginationHtml:<DefaultPagination className={'mzatePaginationClass'}  data={result.edList} 
                            handlePageClick={this.handlePageClick} perPage={this.state.perPage} totalCount={result.totalCount}
                            />})
                    }, 100);

                }
            }).catch((error) =>{
                catchErrorAjax(error, this.setState.bind(this));
            });
        })
    } 

    handlePageClick = (e) => {
        const selectedPage = e.selected+1;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        });
        this.getElectronicDocument(true);
    };

    handleCallback = (event, picker) => {
        handleDateRangeCallback(picker.startDate, picker.endDate, this.setState.bind(this));
    }

    showElectronicDocument = async (eDocument) => {
        let sifenAndStatusFields = await this.canBeEditOrResend(eDocument);
        this.setState({currentEDocument:eDocument,modalContent:"",eDocumentInnominated:false});
        let modalContent = <InvoiceShow token={document.querySelector("#token").value} 
        invoiceId={eDocument.invoiceId} independent={document.querySelector("#independent").value} 
        instanceType={document.querySelector("#instanceType").value} isFromInvoicePanel={true} sifenAndStatusFields={sifenAndStatusFields} />

        let modalTitle = i18n.translate("default_bill") + " " + eDocument.invoiceNumber;
        let buttons = []; 
        setTimeout(()=> {
            if(sifenAndStatusFields.canBeForwarded){
                buttons.push({text: i18n.translate("default_edit"), function: this.editElectronicDocument, class:"editElectronicDocument"},
                {text: i18n.translate("save"), function: this.saveElectronicDocument, class:"displayNone buttonMzatePrimary saveNewElectronicDocument"},
                {text: i18n.translate("default_resend"), function: this.resendElectronicDocument, class:"resendElectronicDocument"})
            } 
            if(sifenAndStatusFields.canCancel){
                buttons.push({text: i18n.translate("cancel_invoice"), function: this.cancelElectronicDocument, class:"cancelElectronicDocument"})
            } 
            buttons.push({text: i18n.translate("default_close"), function: this.cancelEditElectronicDocument, class:"cancelEditElectronicDocument"})   
            this.setState({modalContent:<DefaultModal show={true} title={modalTitle}
            content={modalContent} buttons={buttons} size='lg'/>})
        }, 10);
        setTimeout(() => {
            document.querySelectorAll(".invoiceShowContainer")[0].style.maxHeight = "100%"
        }, 20);
    }

    canBeEditOrResend = async (eDocument) => {
        try {
            const response = await DefaultAjax('').get(`/fePyElectronicDocument/dataForEdit?id=${eDocument.id}`);
            if (response) {
                const result = JSON.parse(response.data);
                this.setState({ result });
                return result;
            }
        } catch (error) {
            catchErrorAjax(error, this.setState.bind(this));
        }
    } 

    cancelElectronicDocument = () => {
        this.setState({modalContent2:""});
        let modalContent = <>
            <label>{i18n.translate("enter_the_motive_of_cancelling_electronic_document")}</label>
            <textarea
                id="reasonForCancelElectronicDocument"
                maxLength={500}
                minLength={5}
                rows="5"  
                placeholder={i18n.translate("write_up_to_500_characters")} 
                style={{width:'100%'}}
                className="form-control"
            />
        </>;
        let modalTitle = i18n.translate("reason_for_cancellation");
        let buttons = [{text: i18n.translate("default_request"), function: this.confirmCancelElectronicDocument},
            {text: i18n.translate("cancel"), function: ""}] 
        setTimeout(() => {
            this.setState({modalContent2:<DefaultModal show={true} title={modalTitle} content={modalContent} 
            buttons={buttons}/>})
        }, 10);
    } 

    confirmCancelElectronicDocument = () => {
        let model = {
            documentReplicaId: this.state.currentEDocument.replicaId,
            documentType: this.state.currentEDocument.documentType,
            cancelledReason: document.querySelector("#reasonForCancelElectronicDocument").value
        } 
        if(model.cancelledReason.length < 5){
            toastFunction(i18n.translate("you_must_enter_at_least_5_characters"),"warning");
        }else{
            DefaultAjax('').post('/fePyElectronicDocument/cancelDocument',model)
            .then((res) => {
                if(res){
                    let result = JSON.parse(res.data);
                    if(result === 0 || result === "0"){
                        this.setState({modalContent2:"",modalContent:""});
                        this.showModalRequestElectronicDocumentCancelation();
                    }else{
                        toastFunction(i18n.translate("an_error_has_ocurred"),"error");
                    }    
                }
            }).catch((error) =>{
                catchErrorAjax(error, this.setState.bind(this));
            });
        }  
    } 

    showModalRequestElectronicDocumentCancelation = () => {
        let message = i18n.translate("message_electronic_document_cancelation");
        let modalTitle = i18n.translate("cancellation_requested");
        let buttons = [{text: i18n.translate("default_accept"), function: this.initElectronicDocumentIndex}];
        setTimeout(() => {
            this.setState({modalContent: <DefaultModal show={true} title={modalTitle} content={message} 
            buttons={buttons} closeFunction={this.initElectronicDocumentIndex}/>})
        },10); 
    } 

    saveElectronicDocument = () => {
        let typeOfDocument = document.querySelector("#typeOfDocument").value;
        let documentNumber = document.querySelector("#documentNumber").value;
        let customerName = document.querySelector("#customerName").value;
        let taxPayer = null;
        let typeTaxPayer = document.querySelector("#typeTaxPayer").value;
        if(typeOfDocument === '' &&  !this.state.eDocumentInnominated){
            toastFunction(i18n.translate("must_select_the_type_of_document"),"warning");
        }else if((documentNumber === '' || customerName === '' || typeOfDocument === '') && !this.state.eDocumentInnominated){
            toastFunction(i18n.translate("you_must_complete_all_fields"),"warning");
        }else{
            if(typeOfDocument === '1'){
                taxPayer = typeOfDocument
                typeOfDocument = null
            }  
            let model = {
                deId:this.state.currentEDocument.id,
                customerName:customerName,
                customerDocumentType:typeOfDocument,
                customerDocumentNumber: documentNumber,
                taxpayer: taxPayer,
                taxpayerType: typeTaxPayer
            } 
            this.showModalConfirmEditElectronicDocument();
            this.setState({eDocumentForEdit:model});
        }   
    }

    showModalConfirmEditElectronicDocument = () => {
        this.setState({modalContent2:""})
        let modalContent = i18n.translate("confirm_edit_electronic_document_message");
        let buttons = [{text: i18n.translate("default_confirm"), function: this.confirmSaveEditElectronicDocument},
            {text: i18n.translate("default_close"), function:""}];
        setTimeout(()=> {
            this.setState({modalContent2:<DefaultModal show={true} title={i18n.translate("default_confirm")}
            content={modalContent} buttons={buttons}/>})
        }, 10);
    } 

    confirmSaveEditElectronicDocument = () => {
        this.setState({
            modalContent2:"",
            spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").value}/>
        });
        DefaultAjax('').post('/fePyElectronicDocument/editAndSendElectronicDocument',this.state.eDocumentForEdit)
        .then((res) => {
            this.setState({
                spinner:""
            })
            let result = JSON.parse(res.data);
            if(result.status === 0 || result.status === "0"){
                toastFunction(i18n.translate("the_electronic_document_was_modified"),"success");
                this.getElectronicDocument(true);
            }else{
                setTimeout(()=> {
                    this.setState({
                        modalContent2:<DefaultModalError errorList={result.errorsList} status={result.status} />
                    });
                }, 10);
            }
        }).catch((error) =>{
            catchErrorAjax(error, this.setState.bind(this));
        });
    } 
    
    cancelEditElectronicDocument = () => {
        const editButton = document.querySelector(".editElectronicDocument");
        const cancelElectronicDocument = document.querySelector(".cancelElectronicDocument");
        if (editButton?.classList.contains("displayNone")){
            document.querySelector(".editElectronicDocument").classList.remove("displayNone");
            document.querySelector(".resendElectronicDocument").classList.remove("displayNone");
            document.querySelector(".saveNewElectronicDocument").classList.add("displayNone");
            if(cancelElectronicDocument){
                cancelElectronicDocument.classList.remove("displayNone");
            } 
            document.querySelector(".cancelEditElectronicDocument").innerText = i18n.translate("default_close");
            document.querySelector(".typeTaxPayerRow").classList.add("displayNone");
            document.querySelector(".electronicDocumentEvent").classList.remove("displayNone");

            document.querySelector(".modalBody").scrollTop = 0;
            const elementsToShow = document.querySelectorAll(".showWhenEditInvoice");
            elementsToShow.forEach(element => {
                element.classList.add("displayNone");
            });
            const elementsToHide = document.querySelectorAll(".hideWhenEditInvoice")
            elementsToHide.forEach(element => {
                element.style.display = '';
            });
        }else{
            this.setState({modalContent:""})
        }  
    } 

    sendNow = () => {
        DefaultAjax('').post('/fePyElectronicDocument/sendNow')
            .then((res) => {
                if(res.status === 200){
                    this.initElectronicDocumentIndex();
                    toastFunction(i18n.translate("electronic_documents_sent"),"success");
                }else{
                    toastFunction(i18n.translate("an_error_has_ocurred"),"error");
                } 
            }).catch((error) =>{
                catchErrorAjax(error, this.setState.bind(this));
            });
    } 

    resendElectronicDocument = () => {
        DefaultAjax('').post('/fePyElectronicDocument/sendElectronicDocument?id='+this.state.currentEDocument.id)
            .then((res) => {
                if(res.status === 200){
                    this.initElectronicDocumentIndex();
                    this.setState({modalContent:""})
                    toastFunction(i18n.translate("electronic_document_forwarded"),"success");
                }else{
                    toastFunction(i18n.translate("an_error_has_ocurred"),"error");
                } 
            }).catch((error) =>{
                catchErrorAjax(error, this.setState.bind(this));
            });
    } 
    
    editElectronicDocument = () => {
        const cancelElectronicDocument = document.querySelector(".saveNewElectronicDocument");
        const electronicDocumentEvent = document.querySelector(".electronicDocumentEvent");
        document.querySelector(".editElectronicDocument").classList.add("displayNone");
        document.querySelector(".resendElectronicDocument").classList.add("displayNone");
        document.querySelector(".saveNewElectronicDocument").classList.remove("displayNone","buttonMzateSecondary");
        if(cancelElectronicDocument){
            cancelElectronicDocument.classList.remove("displayNone");
        } 
        document.querySelector(".cancelEditElectronicDocument").innerText = i18n.translate("cancel");
        if(electronicDocumentEvent){
            document.querySelector(".electronicDocumentEvent").classList.add("displayNone");
        } 

        document.querySelector(".modalBody").scrollTop = 0;
        const elementsToShow = document.querySelectorAll(".showWhenEditInvoice");
        elementsToShow.forEach(element => {
            element.classList.remove("displayNone");
        });
        const elementsToHide = document.querySelectorAll(".hideWhenEditInvoice")
        elementsToHide.forEach(element => {
            element.style.display = 'none';
        });
        document.querySelector("#documentNumber").value = this.state.currentEDocument.customerFiscalNumber;
        document.querySelector("#customerName").value = this.state.currentEDocument.customerName
        const validDocumentTypes = [0, 2];
        if (this.state.result.documentType != null && validDocumentTypes.includes(Number(this.state.result.documentType))){
            document.querySelector("#typeOfDocument").value = this.state.result.documentType;
        }else if(this.state.result.documentType == null || this.state.result.documentType === 1){
            document.querySelector("#typeOfDocument").value = 1;
            document.querySelector(".typeTaxPayerRow").classList.remove("displayNone");
        }else if(this.state.result.documentType === ''){
            this.setState({eDocumentInnominated:true})
        }  
        if(this.state.result.isTapayer){
            document.querySelector("#taxPayer").value = 1;
        }else{
            document.querySelector("#taxPayer").value = 2;
        }  
        document.querySelector("#typeTaxPayer").value = this.state.result.taxpayerType;  
    } 

    render(){
        return(
            <>
                {this.state.modalContent}
                {this.state.modalContent2}
                <nav className='navbar'>
                    <Row>
                        {this.state.selectOrgType} 
                        <Col>
                            <label style={{display: "block"}}>{i18n.translate("date")}</label>
                            <div>
                                <DateRangePicker
                                    initialSettings={{ startDate: this.state.startDate, endDate: this.state.endDate,
                                        locale : {
                                            applyLabel: this.state.language === 'es' ? 'Aplicar' : 'Apply',
                                            cancelLabel: this.state.language === 'es' ? 'Cancelar' : 'Cancel',
                                        }
                                     }}
                                     onEvent={this.handleCallback}
                                >
                                    <input type="text" readOnly style={{fontSize: "12px",padding: "5px",cursor:"pointer"}} 
                                    className="form-control inputStandard DateRangePicker"/>
                                </DateRangePicker>
                                <input type="hidden" id="dateInInputStart"/>
                                <input type="hidden" id="dateInInputEnd"/>
                            </div>
                        </Col> 
                        <Col>
                            <label style={{display: "block"}}>{i18n.translate("default_client")}</label>
                            <input type="text" id="customerToSearch" className="form-control inputStandard"/>
                        </Col>
                        <Col>
                            <label style={{display: "block"}}>{i18n.translate("document_number")}</label>
                            <input type="text" id="documentNumberToSearch" className="form-control inputStandard"/>
                        </Col>
                        <Col>
                            <label style={{display: "block"}}>{i18n.translate("invoice_number")}</label>
                            <input type="text" id="invoiceNumberToSearch" className="form-control inputStandard"/>
                        </Col>
                        <Col>
                            <label style={{display: "block"}}>{i18n.translate("state")}</label>
                            <select className="form-select selectStandard" id="statusForFilter">
                                <option value='' selected>{i18n.translate("default_all")}</option>
                                <option value='GENERATED'>{i18n.translate("GENERATED")}</option>
                                <option value='SENT'>{i18n.translate("SENT")}</option>
                                <option value='FINISHED'>{i18n.translate("FINISHED")}</option>
                            </select>   
                        </Col>
                        <Col>
                            <label style={{display: "block"}}>{i18n.translate("default_result")}</label>
                            <select className="form-select selectStandard" id="resultForFilter">
                                <option value='0' selected>{i18n.translate("default_all")}</option>
                                <option value='1'>{i18n.translate("default_approved")}</option>
                                <option value='2'>{i18n.translate("default_refused")}</option>
                            </select>   
                        </Col>
                    </Row>
                    <div style={{ paddingTop: "22px" }}>
                        <div style={{ display: 'inline-block' }} className="divIndex">
                            <button className='buttonMzateSecondary' onClick={()=> this.getElectronicDocument(true)}>
                                <FaIcons.FaSearch />
                                <span>{i18n.translate("default_search")}</span>
                            </button>
                        </div>
                        {' '}
                        <div style={{ display: 'inline-block' }} className="divIndex">
                            <button className='buttonMzateSecondary' onClick={()=> this.getElectronicDocument(false,true)}>
                                <FaIcons.FaSearch />
                                <span>{i18n.translate("default_show_all")}</span>
                            </button>
                        </div>
                    </div>
                </nav>
                <br/>
                <div>
                    {this.state.spinner}
                    <div id="divTitle" style={{textAlign:'center'}}>
                        <span className="title" style={{float:'left'}}>{i18n.translate("electronic_documents")}</span>
                        <div style={{display:'inline-block'}}>
                            <div>
                                <label style={{marginRight:'10px'}}><span className="bolder">{i18n.translate("documents_pending_submission")}: </span>{this.state.numberOfPendingDocuments}</label> 
                                <label style={{marginRight:'10px'}}>
                                    <span className="bolder">{i18n.translate("state")}: </span>
                                {this.state.eDocumentStatus === 0 ? i18n.translate("inProcess") : i18n.translate("on_hold")}</label> 
                                    <span role="button" tabIndex="0" className="sendNowEDocumentButton" onClick={()=> this.sendNow()}>
                                        {i18n.translate("send_now")} <FaIcons.FaUpload/>
                                    </span>
                            </div>
                            <div style={{marginTop:'7px'}}>
                                <label style={{marginRight:'10px'}}><span className="bolder">{i18n.translate("last_execution")}: </span>{this.state.lastExecution}</label>
                                <label style={{marginRight:'10px'}}><span className="bolder">{i18n.translate("next_execution")}: </span>{this.state.nextExecution}</label> 
                            </div>
                        </div>
                        {this.state.paginationHtml}
                    </div>
                    <div className='bodyContent' id='bodyContent'>
                        <Table className='table table-hover'>
                            <tbody>
                                <tr>
                                    <th className='firstcell'>{i18n.translate("date_issue")}</th>
                                    <th className='cellgroup'>{i18n.translate("invoice_number")}</th>
                                    <th className='cellgroup'>{i18n.translate("invoice_customer_name")}</th>
                                    <th className='cellgroup'>{i18n.translate("document_number")}</th>
                                    <th className='cellgroup'>{i18n.translate("document_electronic_canceled")}</th>
                                    <th className='cellgroup'>{i18n.translate("net_total")}</th>
                                    <th className='cellgroup'>{i18n.translate("state")}</th>
                                    <th className="cellgroup">{i18n.translate("default_result")}</th>
                                    <th className='cellgroup'>{i18n.translate("response_date")}</th>
                                </tr>
                                {this.state.contentPagination}
                                {this.state.arrayElectronicDocument.length === 0 && <tr>
                                        <td colSpan={10} style={{textAlign:'center'}}>
                                            {i18n.translate("there_are_no_results_for_the_search")} 
                                        </td>
                                    </tr>} 
                            </tbody>
                        </Table>
                    </div>
                </div>
            </>
        )
    } 
} 

ElectronicDocument.propTypes = {
    token: PropTypes.string.isRequired
};

export default ElectronicDocument;