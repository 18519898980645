import styled from "styled-components";
import * as FaIcons from "react-icons/fa";
import { TopbarData, TopbarDataCollapse } from "./TopbarData";
import Menus from "./Menus";
import { IconContext } from "react-icons/lib";
import i18n from "i18n-react";
import { useEffect, useState } from "react";
import axios from 'axios';
import { DefaultAjax } from "./DefaultAjax";
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import PropTypes from 'prop-types';
const url = process.env.REACT_APP_API_URL;

const Nav = styled.div`
    background: #15171c;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    `;

const Topbar = ({isInvoiceShow}) => {
    const [topbarData, setTopbarData] = useState([]);
    const [topbarDataCollapse, setTopbarDataCollapse] = useState([]);
    const [iconCollapseModules, setIconCollapseModules] = useState("");
    const [serviceName, setServiceName] = useState(""); 
    const [userFullName, setUserFullName] = useState("");
    const [ajaxFlag, setAjaxFlag] = useState(false);
    const [platformUrl, setPlatformUrl] = useState("");
    const [pathRoot, setPathRoot] = useState(""); 
    const [token, setToken] = useState("");
    const [maxElement, setMaxElement] = useState("");
    const [instanceType, setInstanceType] = useState("");  
    const [independent, setIndependent] = useState("");
    const [currentModule, setCurrentModule] = useState("");
    const [defaultCountryCenter, setDefaultCountryCenter] = useState("");
    const [userId, setUserId] = useState("");
    const [notificationsCount,setNotificationsCount] = useState("");
    const [eventList, setEventList] = useState([]);
    const [isOpenModalNotification, setIsOpenModalNotification] = useState(false);
    const [startDate, setStartDate] = useState(moment().add(-12, 'hour'));
    const [endDate, setEndDate] = useState(moment().add(12, 'hour'));
    const [dateRange, setDateRange] = useState([]);
    const [isAdmin, setIsAdmin] = useState(""); 

    const getCurrentNotification = async () => {
        // Esperar hasta que el token tenga un valor
        let tokenValue = document.querySelector("#token").value;
    
        // Si el token no está disponible, espera un momento y vuelve a comprobar
        while (!tokenValue) {
            await new Promise(resolve => setTimeout(resolve, 100)); // Espera 100 ms
            tokenValue = document.querySelector("#token").value;
        }
    
        // Luego, realiza la solicitud Ajax
        try {
            const res = await DefaultAjax('').get("/adminNotifications/reloadEventNotifications");
            
            if (res) {
                let data = JSON.parse(res.data);
                if (data.total !== 0) {
                    setNotificationsCount(data.total);
                    document.querySelector("#notificationCount").style.display = '';
                    if (data.total > 9) {
                        document.querySelector("#notificationCount").style.paddingTop = "8px";
                        document.querySelector("#notificationCount").style.paddingBottom = "8px";
                    }
                } else {
                    document.querySelector("#notificationCount").style.display = 'none';
                    document.querySelector("#notificationsCount").style.display = 'none';
                }
            }
        } catch (error) {
            
        }
    };

    useEffect(() => {
        if(!ajaxFlag){
            axios.get(url+"globalConfig.json").then(response => {
                setPathRoot(response.data.pathRoot);
                let currentUrl = window.location.href;
                let params = currentUrl.split("?token=");
                let modules = params[0].split("?module=");
                setCurrentModule(modules[1]);
                let token = decodeURIComponent(params[1]);
                setInstanceType(response.data.instanceType);
                setIndependent(response.data.independent);
                setPlatformUrl(response.data.platformUrl);
                setToken(token)
                axios({
                    method: 'GET',
                    url: url+"session/validateTokenForPlatform?token="+token,
                    headers: {'Authorization': token},
                    transformResponse: [(data) => {
                        let maxWidth = document.querySelector("#nav-tab").clientWidth;
                        let maxElement = Math.floor(maxWidth / 150);
                        setMaxElement(maxElement);
                        let result = data.split("|");
                        setUserId(result[0])
                        setServiceName(result[5]);
                        if(result?.[6]){
                            setUserFullName(result[6]);
                        } 
                        setDefaultCountryCenter(result[8])
                        let roles = result[4].split(",");
                        let rolesToHaveUser = roles;
                        setTopbarDataCollapse(TopbarDataCollapse(rolesToHaveUser));
                        
                        let roleSet = new Set(rolesToHaveUser);
                        setIsAdmin(roleSet.has("ROLE_CMP_ADMIN"))

                        setTopbarData(TopbarData(rolesToHaveUser));
                        let dateRange = [];
                        dateRange.push(dateRangeForSearh(new Date(moment().add(-12, 'hour'))));
                        dateRange.push(dateRangeForSearh(new Date(moment().add(12, 'hour'))));
                        setDateRange(dateRange);
                    }]
                })
                setAjaxFlag(true);
            })
        } 
        
    }, [ajaxFlag]);

    const refreshToken = () => {
        DefaultAjax('').get('/session/refreshToken')
        .then((res) => {
            if(res){
                setToken(res.data);
                document.querySelector("#token").value = res.data;
            } 
        })
    }

    useEffect(() => {
        const getNotifications = () => {
            getCurrentNotification();
        };
    
        const refreshTokenInterval = () => {
            refreshToken();
        };
    
        getNotifications();
        const notificationInterval = setInterval(getNotifications, 300000);
        const tokenRefreshId = setInterval(refreshTokenInterval, 1800000);
    
        return () => {
            clearInterval(notificationInterval);
            clearInterval(tokenRefreshId);
        };
    }, []); 
    

    const showEventNotificationDetail = (event) => {
        if(document.querySelector("#eventNotificationRowDetail-"+event.id).style.display === "none"){
            let rowsElement = document.querySelectorAll(".eventNotificationRowDetail");
            let arrayRowsElement = Array.from(rowsElement);
            arrayRowsElement.forEach((row) => {
                row.style.display = "none";
            })
            document.querySelector("#eventNotificationRow-"+event.id).classList.remove("unreadNotification")
            let rowsOpen = document.querySelectorAll(".eventNotificationRowOpen");
            if(rowsOpen != null){
                let arrayrowsOpen = Array.from(rowsOpen);
                arrayrowsOpen.forEach((li) =>{
                    li.style.background = "";
                    if(li.classList.contains("eventNotificationRowOpen")){
                        li.classList.remove("eventNotificationRowOpen");
                    } 
                })
            }
            document.querySelector("#eventNotificationRowDetail-"+event.id).style.display = "";
            document.querySelector("#eventNotificationRow-"+event.id).classList.remove("unreadNotification");
            document.querySelector("#eventNotificationRow-"+event.id).classList.add("eventNotificationRowOpen");
            
            if(event.read === false || event.read === "false"){
                updateReadValue(event);	
            }
            
        }else{
            document.querySelector("#eventNotificationRowDetail-"+event.id).style.display = "none";
            let rowsOpen = document.querySelectorAll(".eventNotificationRowOpen");
            if(rowsOpen != null){
                let arrayrowsOpen = Array.from(rowsOpen);
                arrayrowsOpen.forEach((li) =>{
                    if(li.classList.contains("eventNotificationRowOpen")){
                        li.classList.remove("eventNotificationRowOpen");
                    } 
                })
            }
        }
    }

    const updateReadValue = (event) => {
        DefaultAjax('').put("/adminNotifications/updateReadValue?replicaId="+encodeURIComponent(event.replicaId))
        .then((res) => {
            if(res){
                JSON.parse(res.data);
            }
        }).catch((error) =>{
            
        });
    }

    const dateToTimeZone = (date,TimeZoneId) => {
        let dateToReturn = new Date(date).toLocaleString({ timeZone: TimeZoneId });
        dateToReturn = dateToReturn.split(",");
        let time = dateToReturn[1]
        dateToReturn = dateToReturn[0].split("/");
        dateToReturn[0] = (dateToReturn[0] < 10) ? '0' + dateToReturn[0] : dateToReturn[0];
        dateToReturn[1] = (dateToReturn[1] < 10) ? '0' + dateToReturn[1] : dateToReturn[1];
        return dateToReturn[0]+"-"+dateToReturn[1]+"-"+dateToReturn[2]+" "+time
    }

    const timeLongAgo = (dateStr,timeZoneId) => {

        const fechaActual = new Date().toLocaleString("en-US", {timeZone: timeZoneId});
	        
	        const fecha = new Date(dateStr).toLocaleString("en-US", {timeZone: timeZoneId});
	        
	        const diferencia = new Date(fechaActual) - new Date(fecha);
	        
	        const dias = Math.floor(diferencia / (1000 * 60 * 60 * 24));
	        const horas = Math.floor((diferencia % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	        const minutos = Math.floor((diferencia % (1000 * 60 * 60)) / (1000 * 60));
	        
	        let textDay = String(i18n.translate("days"));
            let textHour = String(i18n.translate("hours"));
            let textMinutes = String(i18n.translate("minutes"));
	        
	        let message = "";

	        if (dias > 0) {
	            if (dias === 1) {
	            	textDay = String(i18n.translate("day"));
	            }
	            message += dias;
	            message += " ";
	            message += textDay;
	            message += " ";
	        }

	        if (horas > 0) {
	            if (horas === 1) {
	            	textHour = String(i18n.translate("hour"));
	            }
	            if (dias > 0) {
	            	message += ", ";
	            }
	            message += horas;
	            message += " ";
	            message += textHour;
	        }

	        if (minutos > 0) {
	            if (minutos === 1) {
	            	textMinutes = String(i18n.translate("minute"));
	            }
	            if (dias > 0 || horas > 0) {
	            	message += ", ";	
	            }
	            message += minutos;
	            message += " ";
	            message += textMinutes;
	        }
	        
		    return message;
            
    }

    const indexEventNotifications = (event) => {
        setIsOpenModalNotification(false);
        event.stopPropagation();
        document.querySelector("#dateInInputStart").value = dateRange[0];
        document.querySelector("#dateInInputEnd").value = dateRange[1];
        document.querySelector("#notificationCount").style.display = 'none';
        DefaultAjax('').get("/adminNotifications/getEventsForNotifyWeb?dateFrom="+dateRange[0]+"&dateTo="+dateRange[1])
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                for(let i = 0; i < result.length; i++){
                    result[i].json = JSON.parse(result[i].json)
                }
                setEventList(result);
                setIsOpenModalNotification(true);
            }
        }).catch((error) =>{
            
        });
    }

    const showUserInfo = () => {
        if(document.querySelector(".dropdown-menu").style.display === "block"){
            document.querySelector(".dropdown-menu").style.display = "none";
        }else{
            document.querySelector(".dropdown-menu").style.display = "block";
        }
    }

    const userPreferences = (e) => {
        if(e.target.id === "indexConfig" || e.target.id === "userConfiguration"){
            window.location.href = platformUrl+pathRoot+"login/receiptTokenFromAppWeb?tokenOfUser="+token+"&urlToRedirect=/html/dashboardMain.html?lang=es?module=userConfigs";
        }else if(e.target.id === "indexTickets" || e.target.id === "userTickets"){
            window.location.href = platformUrl+pathRoot+"login/receiptTokenFromAppWeb?tokenOfUser="+token+"&urlToRedirect=/html/dashboardMain.html?lang=es?module=userTickets";
        }else if(e.target.id === 'tutorialVideos'){
            let currentUrl = window.location.origin;
            window.open(currentUrl+"?module=tutorialVideos?token="+token,'_blank');
        }   
    } 

    const handleCallback = (start, end) => {
        setDateRange("");
        let dateRange = []; 
        document.querySelector("#dateInInputStart").value = dateRangeForSearh(start._d);
        document.querySelector("#dateInInputEnd").value = dateRangeForSearh(end._d);
        dateRange.push(dateRangeForSearh(start._d));
        dateRange.push(dateRangeForSearh(end._d));
        setDateRange(dateRange);
        setStartDate(start._d);
        setEndDate(end._d);
    }

    const dateRangeForSearh = (date) => {
        let startDate = new Date(date);
        let m = startDate.getMonth() + 1;
        let mes = (m < 10) ? '0' + m : m;
        let day = startDate.getDate();
        let dayForShow = (day < 10) ? '0' + day : day;
        startDate = dayForShow+"-"+mes+"-"+startDate.getFullYear();
        return startDate;
    }

    return (
        <IconContext.Provider value={{ color: "#fff" }}>
            <input type="hidden" id="dateInInputStart"/>
            <input type="hidden" id="dateInInputEnd"/>
            <Modal isOpen={isOpenModalNotification} 
            modalTransition={{ timeout: 0 }} backdropTransition={{ timeout: 0 }} 
            size="lg">
                <ModalHeader className='modalHeader'>
                    {i18n.translate("notifications")} 
                    <button className='close' data-dismiss="close" onClick={()=>setIsOpenModalNotification(false)}>x</button>
                </ModalHeader>
                <ModalBody className={'modalBody modalWithOptionsItem'}>
                    <>
                        <br></br>
                        <div className="row">
                            <div className="col">
                                <DateRangePicker
                                    initialSettings={{ startDate: startDate, endDate: endDate }}
                                    onCallback={handleCallback}
                                >
                                    <input type="text" readOnly style={{fontSize: "12px",padding: "5px",cursor:"pointer"}} className="form-control inputStandard" />
                                </DateRangePicker>
                            </div>
                            <div className="col">
                                <button className="buttonMzateSecondary" onClick={indexEventNotifications}>
                                    {i18n.translate("default_search")}
                                    <FaIcons.FaSearch style={{color:'black',marginLeft:'10px'}}/>
                                </button>
                            </div>
                        </div>
                        <hr></hr>
                        <div style={{textAlign:'center'}}>
                            {eventList.length > 0 && eventList.map((event,i) =>{
                                return(
                                    <div key={event.id} className={event.read ? 'row eventNotificationRow' : 'row eventNotificationRow unreadNotification'}
                                     onClick={()=>showEventNotificationDetail(event)} id={'eventNotificationRow-'+event.id}>
                                        <div className="col-12">
                                            {event.totalBatteryPositive !== 0 && <>
                                                <FaIcons.FaBatteryFull data-tooltip-id="battery-tooltip" style={{color:'black'}}/>
                                                <span className="bolder">({event.totalBatteryPositive})</span>
                                            </>
                                            }
                                            <Tooltip id="battery-tooltip" place="top"
                                                content={i18n.translate("battery_percentage")}/>
                                            {event.totalBatteryNegative !== 0 && <>
                                                <FaIcons.FaBatteryFull data-tooltip-id="battery-tooltip" style={{color:'#FEA604'}}/>
                                                <span className="bolder">({event.totalBatteryNegative})</span>
                                            </>
                                            }
                                            {event.totalDisconnectedPositive !== 0 && <>
                                                <FaIcons.FaSignal data-tooltip-id="disconnection-tooltip" style={{color:'black'}}/>
                                                <span className="bolder">({event.totalDisconnectedPositive})</span>
                                            </>
                                            }
                                            <Tooltip id="disconnection-tooltip" place="top"
                                                content={i18n.translate("disconnection")}/>
                                            {event.totalDisconnectedNegative !== 0 && <>
                                                <FaIcons.FaSignal data-tooltip-id="disconnection-tooltip" style={{color:'#FEA604'}}/>
                                                <span className="bolder">({event.totalDisconnectedNegative})</span>
                                            </>
                                            }
                                            {event.totalGeofencingPositive !== 0 && <>
                                                <FaIcons.FaMapMarkedAlt data-tooltip-id="outOfGeofencing-tooltip" style={{color:'black'}}/>
                                                <span className="bolder">({event.totalGeofencingPositive})</span>
                                            </>
                                            }
                                            <Tooltip id="outOfGeofencing-tooltip" place="top"
                                                content={i18n.translate("out_of_geofencing")}/>
                                            {event.totalGeofencingNegative !== 0 && <>
                                                <FaIcons.FaMapMarkedAlt data-tooltip-id="outOfGeofencing-tooltip" style={{color:'#FEA604'}}/>
                                                <span className="bolder">({event.totalGeofencingNegative})</span>
                                            </>
                                            }
                                            - {event.date}
                                        </div>
                                        <div className="eventNotificationRowDetail col-12" style={{display:'none'}} id={'eventNotificationRowDetail-'+event.id}>
                                            <div className="row">
                                                <div className="col bolder">{i18n.translate("alert_type_event_notification")}</div>
                                                <div className="col bolder">{i18n.translate("event_date")}</div>
                                                <div className="col bolder">{i18n.translate("time_elapsed")}</div>
                                                <div className="col bolder">{i18n.translate("device_events_value")}</div> 
                                                <div className="col bolder">{i18n.translate("organizationalNode_users")}</div>
                                            </div>
                                            {event.json.map((detail) => {
                                                return(
                                                    <div className="row" key={detail.id} style={{borderTop: '1px solid gray',paddingTop:'5px'}}>
                                                        <div className="col">
                                                            {detail.alert && detail.eventType === 'battery.percentage' && 
                                                                <FaIcons.FaBatteryFull style={{color:'#FEA604'}}/>
                                                            } 
                                                            {!detail.alert && detail.eventType === 'battery.percentage' && 
                                                                <FaIcons.FaBatteryFull style={{color:'black'}}/>
                                                            }
                                                            {detail.alert && detail.eventType === 'disconnection' && 
                                                                <FaIcons.FaSignal style={{color:'#FEA604'}}/>
                                                            } 
                                                            {!detail.alert && detail.eventType === 'disconnection' && 
                                                                <FaIcons.FaSignal style={{color:'black'}}/>
                                                            }
                                                            {detail.alert && detail.eventType === 'out.of.geofencing' && 
                                                                <FaIcons.FaMapMarkedAlt style={{color:'#FEA604'}}/>
                                                            } 
                                                            {!detail.alert && detail.eventType === 'out.of.geofencing' && 
                                                                <FaIcons.FaMapMarkedAlt style={{color:'black'}}/>
                                                            }
                                                        </div>
                                                        <div className="col">{dateToTimeZone(detail.eventDate,detail.eventTimeZoneId)}</div>
                                                        <div className="col">{timeLongAgo(detail.eventDate,detail.eventTimeZoneId)}</div>
                                                        <div className="col">
                                                            {detail.eventType === 'battery.percentage' && <>
                                                                {detail.eventValue} {detail.unitOfMeasurement}
                                                            </>} 
                                                            {detail.eventType !== 'battery.percentage' && <>
                                                                -
                                                            </>}
                                                        </div>
                                                        <div className="col">{detail.eventFullname} - {detail.eventUsername}</div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })}
                            {eventList.length === 0 && <>{i18n.translate("there_are_no_results_for_the_search")}</>}
                        </div>
                    </>
                </ModalBody>
                <ModalFooter>
                    <button className="buttonMzatePrimary" onClick={()=>setIsOpenModalNotification(false)}>{i18n.translate("default_accept")}</button>
                </ModalFooter>
            </Modal>
            <Nav id="nav-top" style={{display: isInvoiceShow ? 'none': ''}}>
                <input type="hidden" id="token" value={token}/>
                <input type="hidden" id="instanceType" value={instanceType}/>
                <input type="hidden" id="independent" value={independent}/>
                <input type="hidden" id="platformUrl" value={platformUrl}/>
                <input type="hidden" id="pathRoot" value={pathRoot}/>
                <input type="hidden" id="currentModule" value={currentModule}/>
                <input type="hidden" id="defaultCountryCenter" value={defaultCountryCenter}/>
                <input type="hidden" id="userFullname" value={userFullName}/>
                <input type="hidden" id="userId" value={userId}/>
                <input type="hidden" id="isAdmin" value={isAdmin}/> 
                <div className="nav nav-tabs nav-fill fixed-top" id="nav-tab" role="tablist">
                    {topbarData.map((item, index) => {
                        return (
                            <>
                                {index <= maxElement &&
                                    <Menus item={item} index={index} platformUrl={platformUrl} pathRoot={pathRoot} token={token} />
                                }
                            </>
                        );
                    })}
                    {topbarDataCollapse.length > maxElement+1 &&
                        <li className="nav-item dropdown dropdownModulesCollapse"
                            onMouseEnter={() => setIconCollapseModules("dropdown-modulesCollapseShow")}
                            onMouseLeave={() => setIconCollapseModules("")}
                        >
                            <a>
                                <FaIcons.FaBars style={{color:'black',fontSize: '35px'}}/>
                            </a>
                            <div className={'dropdown-modulesCollapse '+iconCollapseModules}>
                                {topbarDataCollapse.map((item, index) => {
                                    return(
                                        <>
                                            {index > maxElement &&
                                                <Menus item={item} index={index} platformUrl={platformUrl} pathRoot={pathRoot} token={token}/>
                                            }
                                        </>
                                    )
                                })}
                            </div>
                        </li>
                    }
                    
                </div>
                <li className="nav-item dropdown" id="nav-user" onClick={showUserInfo}>
                    <a className="nav-link dropdown-toggle" id="nav-usuario-tab" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                        <div id="userInfoContainer">
                            <div id="userIcon">
                                <FaIcons.FaUserCircle/>
                                <span id="notificationCount" style={{display: 'none'}} onClick={indexEventNotifications}>{notificationsCount}</span>
                            </div>
                        <div id="userInfo">
                            <div id="userName">
                               {i18n.translate("hello")}, {userFullName} 
                            </div>
                            <div id="serviceName">
                                {serviceName} 
                            </div>
                        </div>
                        
                        </div>
                    </a>
                    <div className="dropdown-menu">
                    <a className="dropdown-item" id="indexConfig" onClick={userPreferences}>
                        <FaIcons.FaCog className="noPointerEvents"/>
                        <span id="userConfiguration" className="noPointerEvents">{i18n.translate("user_configuration")}</span>
                    </a>
                    <a class="dropdown-item" id="indexEventNotifications" onClick={indexEventNotifications}>
      	                <FaIcons.FaBell/>
      	                <span>{i18n.translate("notifications")}</span>
                        <span className='bolder' style={{color: 'black'}} id="notificationsCount">({notificationsCount})</span>
                    </a>
                    <a className="dropdown-item" id="indexTickets" onClick={userPreferences}>
                        <FaIcons.FaQuestionCircle className="noPointerEvents"/>
                        <span id="userTickets" className="noPointerEvents">{i18n.translate("tickets_title")}</span>
                    </a>
                    <a className="dropdown-item" id="tutorialVideos" onClick={userPreferences}>
                        <FaIcons.FaPlay className="noPointerEvents"/>
                        <span id="userTickets" className="noPointerEvents">{i18n.translate("tutorial_videos")}</span>
                    </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" id="logout" href={platformUrl+pathRoot+"logout"}>
                        <FaIcons.FaSignOutAlt/>
                        <span id="userOut">{i18n.translate("sign_off")} </span>
                    </a>
                    </div>
                </li>
            </Nav>
            
        </IconContext.Provider>
    );
};

Topbar.propTypes = {
    isInvoiceShow: PropTypes.bool.isRequired
};
    
export default Topbar;